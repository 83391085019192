import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';

import Button from '../common/Button';
import Highlighter from './Highlighter';
import Info from './Info';
import Share from './Share';
import DateLabel from './DateLabel';

import { useExpanded } from '../../hooks/useExpanded';

const POST_HEIGHT = '425';

const ContainerOuter = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${(props) => props.largePaddingBottom && 2}rem;
  padding-bottom: 4rem;

  ${up('tablet')} {
    flex-direction: row;
  }
`;

const Container = styled.div`
  width: 100%;
  transition: height ${getAnimationDuration}s ease-in;
  height: ${(props) => (props.expanded ? props.expandedHeight : POST_HEIGHT)}px;
  color: black;
  position: relative;
  display: flex;
  background-color: ${(props) => props.theme.colors.yellow};

  ${up('tablet')} {
    width: 620px;
    order: ${(props) => (props.rightHandSide ? 1 : 0)};
  }

  ${(props) => {
    return (
      props.type === 'Tagebuch' &&
      css`
        background-image: repeating-linear-gradient(
          180deg,
          ${props.theme.colors.notebookBlue} 0,
          ${props.theme.colors.notebookBlue} 1px,
          transparent 1px,
          transparent 25px
        );
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 0 4rem;

        ${up('tablet')} {
          background-image: repeating-linear-gradient(
            180deg,
            ${props.theme.colors.notebookBlue} 0,
            ${props.theme.colors.notebookBlue} 1px,
            transparent 1px,
            transparent 30px
          );
          background-position: 0 5rem;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 1px;
          height: 100%;
          right: ${props.rightHandSide && '2.8rem'};
          left: ${!props.rightHandSide && '2.8rem'};
          background-color: ${props.theme.colors.accent};

          ${up('tablet')} {
            right: ${props.rightHandSide && '4.5rem'};
            left: ${!props.rightHandSide && '4.5rem'};
          }
        }
      `
    );
  }}
`;

const Body = styled.div`
  height: 100%;
  width: 100%;
  padding-left: 0.66rem;
  padding-right: 0.66rem;
  overflow: hidden;

  padding-left: ${(props) =>
    props.type === 'Brief' && props.rightHandSide && '3.46rem'};
  padding-right: ${(props) =>
    props.type === 'Brief' && !props.rightHandSide && '3.46rem'};

  ${up('tablet')} {
    padding-left: 1.33rem;
    padding-right: 1.33rem;
    padding-left: ${(props) =>
      props.type === 'Brief' && props.rightHandSide && '5.83rem'};
    padding-right: ${(props) =>
      props.type === 'Brief' && !props.rightHandSide && '5.83rem'};
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => (props.rightHandSide ? 'flex-end' : 'flex-start')};
  text-align: ${(props) => (props.rightHandSide ? 'right' : 'left')};
  height: 4rem;

  ${up('tablet')} {
    height: 5rem;
  }
`;

const Text = styled.div`
  display: block;
  padding-bottom: ${(props) => props.expanded && '2rem'};
  white-space: pre-wrap;

  ${(props) =>
    props.type === 'Brief'
      ? css`
          font-family: ${props.theme.fonts.monospace};
          font-size: 16px;
          line-height: 23px;
        `
      : css`
          font-family: ${props.theme.fonts.serif};
          font-size: 20px;
          line-height: 25px;
          margin-top: 4px;

          ${up('tablet')} {
            font-size: 25px;
            line-height: 30px;
            margin-top: 5px;
          }
        `}
`;

const ShareButtonContainer = styled.div`
  display: flex;
  flex: 1 0 auto;
  align-items: flex-end;
  z-index: 1;
  position: relative;
  order: ${(props) => (props.rightHandSide ? 0 : 1)};
  justify-content: ${(props) =>
    props.rightHandSide ? 'flex-end' : 'flex-start'};
`;

const MoreButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: ${(props) =>
    props.rightHandSide ? 'flex-start' : 'flex-end'};
  order: ${(props) => (props.rightHandSide ? 1 : 0)};
  flex: 1 0 2.8rem;

  ${up('tablet')} {
    flex: 1 0 4.5rem;
  }
`;

const StickyButton = styled(Button)`
  margin-bottom: 1rem;
  position: sticky;
  bottom: 1rem;
`;

const MoreButton = styled(StickyButton)`
  transform: translateX(${(props) => (props.rightHandSide ? -16 : 16)}px);
  z-index: 1;

  ${up('tablet')} {
    transform: none;
  }
`;

const ShareButton = styled(StickyButton)`
  margin-bottom: 0;
  margin-top: 0;
  transform: translateX(${(props) => (props.rightHandSide ? -32 : 32)}px) scale(1.794);
  transform-origin: top;
  margin-left: ${(props) => (!props.rightHandSide && '0.75px')};
  margin-right: ${(props) => (props.rightHandSide && '0.8px')};

  ${up('tablet')} {
    margin-top: 0;
    margin-bottom: 1rem;
    margin-left: 0;
    margin-right: 0;
    transform: none;
    transform: scale(1.8);
    transform-origin: ${(props) => (props.rightHandSide ? 'right' : 'left')};
  }

  ${(props) =>
    props.theme.hover(`
    background-color: ${props.theme.colors.yellow};
  `)}
`;

const Post = ({
  rightHandSide,
  categoryIndex,
  category,
  dateText,
  author,
  text,
  info,
  dateLabel,
  isPostPage,
  largePaddingBottom,
  postType: { postType },
}) => {
  const textRef = useRef(null);
  const containerRef = useRef(null);
  const [moreButtonVisible, setMoreButtonVisible] = useState(false);
  const [expandedHeight, setExpandedHeight] = useState(0);
  const [expanded, toggleExpanded] = useExpanded();
  const [shareExpanded, toggleShareExpanded] = useExpanded();

  useEffect(() => {
    if (textRef.current.getBoundingClientRect().height > POST_HEIGHT - 75) {
      setMoreButtonVisible(true);
      setExpandedHeight(textRef.current.getBoundingClientRect().height + 125);
    }
  }, []);

  return (
    <>
      {dateLabel && <DateLabel dateLabel={dateLabel} />}
      <ContainerOuter largePaddingBottom>
        <Container
          ref={containerRef}
          rightHandSide={rightHandSide}
          type={postType}
          expanded={expanded || isPostPage}
          expandedHeight={expandedHeight}
        >
          <MoreButtonContainer rightHandSide={rightHandSide}>
            {!isPostPage && moreButtonVisible && (
              <MoreButton
                small
                light
                onClick={toggleExpanded}
                rightHandSide={rightHandSide}
              >
                {expanded ? 'weniger' : 'mehr'}
              </MoreButton>
            )}
          </MoreButtonContainer>
          <Body rightHandSide={rightHandSide} type={postType}>
            <Details rightHandSide={rightHandSide}>
              <div>{dateText}</div>
              <Highlighter text={author} type="author" />
            </Details>
            <Text
              ref={textRef}
              type={postType}
              expanded={expanded || isPostPage}
            >
              <Highlighter text={text} type="text" />
            </Text>
          </Body>
          {info && <Info rightHandSide={rightHandSide} info={info} />}
        </Container>
        <ShareButtonContainer rightHandSide={rightHandSide}>
          <ShareButton
            small
            dark
            onClick={toggleShareExpanded}
            rightHandSide={rightHandSide}
          >
            teilen
          </ShareButton>
          <Share
            text={text}
            author={author}
            dateText={dateText}
            category={category}
            categoryIndex={categoryIndex}
            toggleExpanded={toggleShareExpanded}
            shareExpanded={shareExpanded}
            rightHandSide={rightHandSide}
            isPostPage={isPostPage}
          />
        </ShareButtonContainer>
      </ContainerOuter>
    </>
  );
};

function getAnimationDuration({ expandedHeight }) {
  return expandedHeight > 1200 ? 1.25 : 0.3;
}

export { ShareButtonContainer, MoreButton, Container, ContainerOuter };
export default Post;
