import React from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';

const Container = styled.div`
  top: -0.5rem;
  margin-bottom: 1rem;
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
  padding-left: 12rem;

  ${up('tablet')} {
    font-size: 1.33rem;
    padding-left: 12.7rem;
  }

  > div {
    margin-left: 0.7rem;
  }

  &:before {
    content: '';
    display: block;
    position: relative;
    width: 6px;
    height: 6px;
    background-color: white;
    border-radius: 50%;
    transform: translateX(-2px);
  }
`;

const DateLabel = ({ dateLabel }) => {
  return (
    <Container>
      <div>{dateLabel}</div>
    </Container>
  );
};

export default DateLabel;
