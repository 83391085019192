import React from 'react';
import ReactHighlightWords from 'react-highlight-words';

import stopWords from '../../utils/stopWords';
import { useQuery } from '../../context/queryContext';

const Hightlighter = ({ text }) => {
  const { query } = useQuery();

  return (
    <ReactHighlightWords
      searchWords={getHighlighterSearchWords(query)}
      autoEscape={false}
      textToHighlight={text}
    />
  );
};

function getHighlighterSearchWords(query) {
  if (stopWords.indexOf(query ? query.toLowerCase() : query) < 0) {
    return [query];
  } else {
    return [new RegExp(`\\B${query}\\B|${query}\\B|\\B${query}`, 'ig')];
  }
}

export default Hightlighter;
