import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
  memo,
} from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';

import generateSlug from '../../utils/generateSlug';
import isMobile from '../../utils/isMobile';

import Button from '../common/Button';
import CloseSvg from '../../images/svg/close.svg';
import InstagramSvg from '../../images/svg/instagram.svg';
import TwitterSvg from '../../images/svg/twitter.svg';
import XSvg from '../../images/svg/x.svg';

const StickyContainer = styled.div`
  margin-top: ${(props) => props.scrollUp && '10rem'};

  ${up('tablet')} {
    position: sticky;
    bottom: 1rem;
    top: 1rem;
    height: 390px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const Container = styled.div`
  position: absolute;
  bottom: 17px;
  left: ${(props) => !props.rightHandSide && 0};
  right: ${(props) => props.rightHandSide && 0};
  width: 100%;
  padding-left: 1.33rem;
  padding-right: 1.33rem;
  padding-bottom: 2.5rem;
  border: 1px solid black;
  z-index: 1;
  background-color: ${(props) => props.theme.colors.yellow};
  font-size: 0.66rem;
  color: black;
  transform-origin: bottom
    ${(props) => (props.rightHandSide ? 'right' : 'left')};
  transition: transform 0.2s;
  transform: ${(props) => (props.shareExpanded ? 'scale(1)' : 'scale(0)')};

  ${up('tablet')} {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
    padding-bottom: 1.5rem;
    width: 620px;
    height: 390px;
    bottom: -8px;

    right: ${(props) => !props.rightHandSide && `-39px`};
    left: ${(props) => (props.rightHandSide ? '-87px' : 'initial')};

    transform-origin: bottom
      ${(props) => (props.rightHandSide ? 'left' : 'right')};
  }
`;

const Header = styled.div`
  margin-top: 2rem;
  margin-bottom: 0.6666rem;
`;

const SocialMedia = styled.div`
  display: flex;
`;

const SocialMediaButton = styled.a`
  flex: 1;
  height: 4.25rem;
  padding: 0.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid black;
  cursor: pointer;
  margin-right: 1rem;
  background-color: black;
  color: ${(props) => props.theme.colors.yellow};
  transition: color 0.3s;
  cursor: pointer;

  ${up('tablet')} {
    width: 9.2rem;
  }

  &:last-child {
    margin-right: 0;
  }

  svg {
    height: 1.5rem;
    width: 1.5rem;
    color: ${(props) => props.theme.colors.yellow};
    transition: color 0.3s;
  }

  ${(props) =>
    props.theme.hover(`
    border: 1px solid ${props.theme.colors.accent};
    color: ${props.theme.colors.accent};
    transition: color 0.3s, border 0.3s;

    svg {
      color: ${props.theme.colors.accent};
      transition: color 0.3s, border 0.3s;
    }
  `)}
`;

const Columns = styled.div`
  display: flex;
  flex-direction: column;

  ${up('tablet')} {
    flex-direction: row;
  }

  > div:first-child {
    width: 100%;

    ${up('tablet')} {
      margin-right: ${(props) => !props.rightHandSide && '3rem'};
      margin-left: ${(props) => props.rightHandSide && '3rem'};
      order: ${(props) => props.rightHandSide && 1};
      width: 15rem;
    }
  }

  > div:last-child {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const TextField = styled.div`
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.15rem;
  border: 1px solid black;
  margin-bottom: 0.2rem;

  scrollbar-width: thin;
  scrollbar-color: black transparent;

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 8px;
    border: 3px solid ${(props) => props.theme.colors.yellow};
  }
`;

const LinkTextField = styled(TextField)`
  height: 1.66rem;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: pre;

  &::-webkit-scrollbar {
    height: 8px;
  }
`;

const PostTextField = styled(TextField)`
  height: 5rem;
  overflow-x: hidden;
  overflow-y: scroll;
  white-space: pre-wrap;

  &::-webkit-scrollbar {
    width: 8px;
  }
`;

const Image = styled.div`
  flex: 1;
  border: 1px solid black;
  overflow: hidden;
  position: relative;
  margin-bottom: 0.2rem;
  height: 0;
  padding-bottom: 52.5%;

  ${up('tablet')} {
    height: initial;
    padding-bottom: initial;
  }

  a {
    position: absolute;
  }

  img,
  a {
    width: 100%;
    display: block;

    ${up('tablet')} {
      position: absolute;
      height: 100%;
      width: initial;
      top: 0;
      max-width: initial;
    }
  }
`;

const CloseButton = styled.div`
  height: 25px;
  width: 25px;
  position: absolute;
  bottom: 0;
  cursor: pointer;
  right: ${(props) => props.rightHandSide && 0};
  left: ${(props) => !props.rightHandSide && 0};

  ${(props) =>
    props.theme.hover(`
    .close-stroke {
      stroke: ${props.theme.colors.accent};
    }
  `)}

  ${up('tablet')} {
    left: ${(props) => (props.rightHandSide ? '10px' : 'initial')};
    right: ${(props) => !props.rightHandSide && '10px'};
  }
`;

const SmallButton = styled(Button)`
  width: 4.2rem;
`;

const Share = ({
  toggleExpanded,
  text,
  author,
  dateText,
  category,
  categoryIndex,
  rightHandSide,
  shareExpanded,
  isPostPage,
}) => {
  const postText = `${author}\n${dateText}\n\n${text}`;
  const [copySlugMessage, setCopySlugMessage] = useState('kopieren');
  const [copyPostTextMessage, setCopyPostTextMessage] = useState('kopieren');
  const [copyImageUrlMessage, setCopyImageUrlMessage] = useState('laden');
  const shareContainerRef = useRef();

  const {
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  useEffect(() => {
    if (isMobile && shareExpanded) {
      typeof window !== 'undefined' &&
        setTimeout(() => {
          window.scrollBy({
            top: shareContainerRef.current.getBoundingClientRect().top - 45,
            left: 0,
            behavior: 'smooth',
          });
        }, 500);
    }
  }, [shareExpanded]);

  const slug = useMemo(() => {
    return `${siteUrl}/${generateSlug({
      author,
      dateText,
      categoryIndex,
      category,
    })}`;
  }, [author, dateText, categoryIndex, category, siteUrl]);

  const ogImageUrl = useMemo(() => {
    return `${siteUrl}/og-images/${generateSlug({
      author,
      dateText,
      categoryIndex,
      category,
    })}.jpg`;
  }, [author, dateText, categoryIndex, category, siteUrl]);

  const copyUrl = useCallback(() => {
    navigator.clipboard.writeText(slug).then(
      () => setCopySlugMessage('kopiert'),
      () => setCopySlugMessage('error')
    );
  }, [setCopySlugMessage, slug]);

  const copyPostText = useCallback(() => {
    navigator.clipboard.writeText(postText).then(
      () => setCopyPostTextMessage('kopiert'),
      () => setCopyPostTextMessage('error')
    );
  }, [setCopyPostTextMessage, postText]);

  const copyImageUrl = useCallback(() => {
    navigator.clipboard.writeText(ogImageUrl).then(
      () => setCopyImageUrlMessage('geladen'),
      () => setCopyImageUrlMessage('error')
    );
  }, [setCopyImageUrlMessage, ogImageUrl]);

  return (
    <StickyContainer scrollUp={isPostPage && shareExpanded}>
      <Container
        rightHandSide={rightHandSide}
        shareExpanded={shareExpanded}
        ref={shareContainerRef}
      >
        <Header>Social Media</Header>
        <SocialMedia>
          <SocialMediaButton
            href={ogImageUrl}
            download
            target="_blank"
            rel="noopener"
          >
            <InstagramSvg />
            Auf Instagram teilen
          </SocialMediaButton>
          <SocialMediaButton
            href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
              slug
            )}`}
            target="_blank"
            rel="noopener"
          >
            <XSvg />
            Auf X teilen
          </SocialMediaButton>
        </SocialMedia>
        <Columns rightHandSide={rightHandSide}>
          <div>
            <Header>Link zum Post</Header>
            <LinkTextField>{slug}</LinkTextField>
            <SmallButton small dark onClick={copyUrl}>
              {copySlugMessage}
            </SmallButton>
            <Header>Reiner Text</Header>
            <PostTextField>{postText}</PostTextField>
            <SmallButton small dark onClick={copyPostText}>
              {copyPostTextMessage}
            </SmallButton>
          </div>
          <div>
            <Header>Bildatei</Header>
            {shareExpanded && (
              <Image>
                <a href={ogImageUrl} download>
                  <img src={ogImageUrl} alt="" />
                </a>
              </Image>
            )}
            <SmallButton small dark onClick={copyImageUrl}>
              <a href={ogImageUrl} download>
                {copyImageUrlMessage}
              </a>
            </SmallButton>
          </div>
        </Columns>
        <CloseButton onClick={toggleExpanded} rightHandSide={rightHandSide}>
          <CloseSvg />
        </CloseButton>
      </Container>
    </StickyContainer>
  );
};

export default memo(Share);
