import React, { useRef, memo } from 'react';
import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import useOnClickOutside from 'use-onclickoutside';

import { useExpanded } from '../../hooks/useExpanded';

import Button from '../common/Button';
import CloseSvg from '../../images/svg/close.svg';

const Container = styled.div`
  position: absolute;
  z-index: 1;
  /* top: 0.25rem;
  left: ${(props) => props.rightHandSide && '0.25rem'};
  right: ${(props) => !props.rightHandSide && '0.25rem'}; */

  top: 2rem;
  left: ${(props) => props.rightHandSide && '1.33rem'};
  right: ${(props) => !props.rightHandSide && '1.33rem'};

  ${up('tablet')} {
    top: 2rem;
    left: ${(props) => props.rightHandSide && '1.33rem'};
    right: ${(props) => !props.rightHandSide && '1.33rem'};
  }
`;

const Text = styled.div`
  padding: 20px;
  border: 1px solid black;
  position: relative;
  background: ${(props) => props.theme.colors.yellow};
  padding-left: ${(props) => props.rightHandSide && '60px'};
  padding-right: ${(props) => !props.rightHandSide && '60px'};

  ${up('tablet')} {
    width: 380px;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 5px;
  cursor: pointer;
  left: ${(props) => props.rightHandSide && '9px'};
  right: ${(props) => !props.rightHandSide && '9px'};

  ${(props) =>
    props.theme.hover(`
    svg {
      .close-stroke {
        stroke: ${props.theme.colors.accent};
      }
    }
  `)}
`;

const Info = ({ rightHandSide, info }) => {
  const ref = useRef(null);
  const [expanded, toggleExpanded] = useExpanded();

  useOnClickOutside(ref, () => {
    expanded && toggleExpanded();
  });

  return (
    <Container rightHandSide={rightHandSide} ref={ref}>
      {!expanded && (
        <Button small light onClick={toggleExpanded}>
          info
        </Button>
      )}
      {expanded && (
        <Text rightHandSide={rightHandSide}>
          {info}
          <CloseButton onClick={toggleExpanded} rightHandSide={rightHandSide}>
            <CloseSvg />
          </CloseButton>
        </Text>
      )}
    </Container>
  );
};

export { Container };
export default memo(Info);
